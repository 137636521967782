/* Colors
    wheat:            #c0b087
    background:       #e2d6c6
    blue gray light:  #6f8793
    blue gray dark:   #5a6e73
    sky:              #eaeeef
    sweatshirt light: #bdbdb9
    sweatshirt dark:  #6e6a5e
    jacket light:     #ad7132
    jacket dark:      #87460f
    navabar light:    #fffaf3
*/

.home__background__image {
    background-image: url(assets/home/running_front_cropped.png);
    height: 100vh;
}

.home__footer__image {
    background-image: url(assets/home/shoes.jpg);
    height: 100vh;
}


.home__timeline__image {
    background-image: url(assets/home/timeline.png);
    height: 100vh;
}

@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:600');
@import url('https://fonts.googleapis.com/css2?family=Quattrocento&display=swap');

.title_ {
    font-family: 'Quattrocento', serif;
    font-weight: 400;
    font-size: calc(3vw + 3vh + 1vmin);
    letter-spacing: 0.15em;
    color: #000000;
}

.header__ {
    font-family: 'Quattrocento', serif;
    font-weight: 600;
    font-size: calc(1.2rem + 0.5vw);
    font-style: normal;
    letter-spacing: 0.3em;
    line-height: 2.0;
    /* text-transform: uppercase; */
    color: #3c3d33;
}

.date__ {
    font-family: 'Quattrocento', serif;
    font-weight: 300;
    font-size: calc(2.2rem + 0.5vw);
    font-style: normal;
    letter-spacing: 0.4em;
    line-height: 1.6;
    /* text-transform: uppercase; */
    color: #3c3d33;
}

.address__ {
    font-family: 'Quattrocento', serif;
    font-weight: 600;
    font-size: calc(1.2rem + 0.5vw);
    font-style: normal;
    letter-spacing: 0.3em;
    line-height: 2.0;
    /* text-transform: uppercase; */
    color: #3c3d33;
}


.timeline__ {
    font-family: 'Quattrocento', serif;
    font-weight: 300;
    font-size: calc(2rem + 0.5vw);
    font-style: normal;
    letter-spacing: 0.35em;
    line-height: 1.1;
    /* text-transform: uppercase; */
    color: #3c3d33;
}

.section__title {
    font-family: 'Quattrocento', serif;
    font-weight: 500;
    font-size: 2.6em;
    font-style: normal;
    letter-spacing: 0.1em;
    line-height: 1.5;
    /* text-transform: capitalize; */
    color: #000000;
}

.section__heading {
    font-family: 'Quattrocento', serif;
    font-weight: 400;
    font-style: normal;
    font-size: large;
    letter-spacing: 0.2em;
    line-height: 2;
    text-transform: none;
    color: #000000;
}

.home_background_gradient {
    background: #fffaf380;
    background: -webkit-linear-gradient(rgba(255, 250, 243, 0.5), #e2d6c6);
    background: linear-gradient(rgba(255, 250, 243, 0.5), #e2d6c6)
}