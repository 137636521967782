.navbar__title {
    font-family: 'Quattrocento', serif;
    font-weight: 400;
    font-size: min(2.0em, 5.8vi);
    font-style: normal;
    letter-spacing: 0;
    line-height: 1.5;
    text-transform: capitalize;
    color: #3c3d33;
}

.navbar__item {
    font-family: 'Quattrocento', serif;
    font-size: 1.3em;
}

.btn-primary,
.btn-primary:active,
.btn-primary:visited {
    background-color: #3c3d33 !important;
}

.btn-primary:hover {
    background-color: #6f7060 !important;
}