.background_gradient {
    background: #fffaf380;
    background: -webkit-linear-gradient(#fffaf380, #e2d6c6);
    background: linear-gradient(#fffaf380, #e2d6c6)
}

.faq_title {
    font-family: 'Quattrocento', serif;
    font-weight: 100;
    font-size: min(2.6em, 8vi);
    font-style: normal;
    letter-spacing: 0;
    line-height: 1.5;
    color: #3c3d33;
  }