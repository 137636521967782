.registry_title {
    font-family: 'Quattrocento', serif;
    font-weight: 100;
    font-size: min(3.6em, 8vi);
    font-style: normal;
    letter-spacing: 0;
    line-height: 1.5;
    color: #3c3d33;
  }
  
  .registry__section__text {
    font-family: 'Quattrocento', serif;
    font-weight: 400;
    font-style: normal;
    font-size: large;
    letter-spacing: 0.2em;
    line-height: 2;
    text-transform: none;
    color: #000000;
}
  
  .registry__item__title {
    font-family: 'Quattrocento', serif;
    font-weight: 400;
    font-size: 2.0em;
    font-style: normal;
    letter-spacing: 0;
    line-height: 1.5;
    text-transform: capitalize;
    color: #3c3d33;
  }

  .card_text {
    font-family: 'Quattrocento', serif;
    letter-spacing: 1;
    line-height: 2;
  }