.gallery_title {
  font-family: 'Quattrocento', serif;
  font-weight: 100;
  font-size: min(2.6em, 8vi);
  font-style: normal;
  letter-spacing: 0;
  line-height: 1.5;
  color: #3c3d33;
}

.row {
  margin: 15px;
}

.gallery__image {
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
}

.gallery__image img {
  position: absolute;
}

.gallery__image__vert {
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
}

.gallery__image__vert .img-fluid {
  max-height: 100%;
  width: auto;
}

.gallery__header__text {
  font-family: 'Alegreya SC', serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.2em;
  letter-spacing: 0.3em;
  line-height: 1;
  text-transform: none;
}

.gallery_background_gradient {
  background: #fffaf380;
  background: -webkit-linear-gradient(rgba(255, 250, 243, 0.5), #e2d6c6);
  background: linear-gradient(rgba(255, 250, 243, 0.5), #e2d6c6)
}