.schedule_title {
  font-family: "Quattrocento", serif;
  font-weight: 100;
  font-size: min(3.6em, 8vi);
  font-style: normal;
  letter-spacing: 0;
  line-height: 1.5;
  color: #3c3d33;
}

.schedule_sub_title {
  font-family: "Quattrocento", serif;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0;
  line-height: 1.5;
  color: #3c3d33;
}

.schedule__section__text {
  font-family: "Quattrocento", serif;
  font-weight: 400;
  font-style: normal;
  font-size: large;
  letter-spacing: 0.2em;
  line-height: 2;
  text-transform: none;
  color: #000000;
}

.schedule__item__title {
  font-family: "Quattrocento", serif;
  font-weight: 400;
  font-size: 2em;
  font-style: normal;
  letter-spacing: 0;
  line-height: 1.5;
  text-transform: capitalize;
  color: #3c3d33;
}

.card_text {
  font-family: "Quattrocento", serif;
  letter-spacing: 1;
  line-height: 2;
}

.date_text_a {
  @media only screen and (min-width: 1200px) {
    font-size: 1.2em !important;
    font-family: "Quattrocento", serif;
    font-weight: 600 !important;
    letter-spacing: 0.4dvi !important;
    background-color: #2f3028;
    color: white;
    text-align: center !important;
    border-radius: 12px !important;
    margin: 4px;
    box-shadow: 0px 2px 2px gray !important;
    opacity: 1 !important;
    width: fit-content !important;
    text-wrap: nowrap;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

.timeline_title {
  font-size: 2em;
  color: #000000;
  font-family: "Quattrocento", serif;
  font-weight: 800;
}
