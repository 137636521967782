.footer__text {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 400;
    font-size: 1.0em;
    font-style: normal;
    letter-spacing: 0;
    line-height: 1.5;
    text-transform: capitalize;
    color: #585858;
}

.inner__footer__background__color {
    background-color: rgba(163, 163, 163, 0.2);
}