.thingstodo_background_gradient {
    background: #fffaf380;
    background: -webkit-linear-gradient(rgba(255, 250, 243, 0.5), #e2d6c6);
    background: linear-gradient(rgba(255, 250, 243, 0.5), #e2d6c6)
}

.section__title {
    font-family: 'Quattrocento', serif;
    font-weight: 500;
    font-size: min(2.6em, 8vi);
    font-style: normal;
    letter-spacing: 0.1em;
    line-height: 1.5;
    /* text-transform: capitalize; */
    color: #000000;
}

.section__title_2 {
    font-family: 'Quattrocento', serif;
    font-weight: 300;
    font-size: min(1.5em, 7vi);
    font-style: normal;
    letter-spacing: 0.1em;
    line-height: 1.5;
    /* text-transform: capitalize; */
    color: #000000;
}

.section__title_3 {
    font-family: 'Quattrocento', serif;
    font-weight: 300;
    font-size: min(1.8em, 6vi);
    font-style: normal;
    letter-spacing: 0.1em;
    line-height: 1.5;
    /* text-transform: capitalize; */
    color: #000000;
}

.section__subtitle {
    font-family: 'Quattrocento', serif;
    font-weight: 300;
    font-size: 2.2em;
    font-style: normal;
    letter-spacing: 0.1em;
    line-height: 1.5;
    /* text-transform: capitalize; */
    color: #000000;
}

.section__text {
    font-family: 'Quattrocento', serif;
    color: #333333;
}